// This is a partial.
// It lies in <source>/_sass, just waiting to be imported.
// It does not contain the YAML front matter and has no corresponding output file in the built site.

@font-face {
	font-family: "IBMPlexSans";
	src: url("/assets/fonts/ibmplexsans-light.woff2") format("woff2");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "IBMPlexSans";
	src: url("/assets/fonts/ibmplexsans-medium.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: "IBMPlexSans";
	src: url("/assets/fonts/ibmplexsans-mediumitalic.woff2") format("woff2");
	font-weight: 500;
	font-style: italic;
	font-display: fallback;
}

@font-face {
	font-family: "IBMPlexSans";
	src: url("/assets/fonts/ibmplexsans-bold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: "RocGroteskWide";
	src: url("/assets/fonts/rg-semibold.woff2") format("woff2");
	font-weight: 600; /* Semibold */
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: "RocGroteskWide";
	src: url("/assets/fonts/rg-bold.woff2") format("woff2");
	font-weight: 700; /* Bold */
	font-style: normal;
	font-display: fallback;
}

$ibmPlexSans: "IBMPlexSans", sans-serif;
$rocGroteskWide: "RocGroteskWide", sans-serif;
$subHeadingItalicText: "IBMPlexSansBoldItalic", sans-serif;

body {
	font-family: $ibmPlexSans;
	line-height: 1.6;
	color: $bone;
	color: $oldBone;
	font-size: calc(1rem + 0.25vw);
	font-weight: 300;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
	color: $bone;
}

h1,
.h1 {
	font-family: $rocGroteskWide;
	font-weight: 600;
	font-style: normal;
	line-height: 1.125;
	font-size: calc(1.25rem + 3vw);
	margin-top: 0;
	margin-bottom: calc(1rem + 0.5vw);
}

h2,
.h2,
h3,
.h3,
h4,
.h4 {
	font-family: $ibmPlexSans;
	font-weight: 500;
	margin-top: calc(1rem + 1.5vw);
	margin-bottom: calc(0.5rem + 0.5vw);
	line-height: 1.5;
}

h2 {
	font-size: calc(1.75rem + 0.5vw);
	line-height: 1.25;
	letter-spacing: -0.025em;
}

h3 {
	font-size: calc(1.25rem + 0.33vw);
	line-height: 1.5;
	letter-spacing: 0.02em;
}

h4 {
	font-size: calc(1.125rem + 0.25vw);
	line-height: 1.6;
	letter-spacing: 0.02em;
}

p {
	margin-top: 0;
	margin-bottom: calc(1rem + 0.5vw);
}

a {
	position: relative;
	color: $gum;
	font-family: $ibmPlexSans;
	font-weight: 500;

	&:not(.button) {
		&:hover {
			text-underline-offset: 0.2em;
		}
	}

	h1,
	h2,
	h3,
	h4 {
		color: $night;
		text-decoration: none;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
p,
a,
li {
	word-break: break-word;
}

a {
	//white-space: nowrap;
}

strong {
	font-family: $ibmPlexSans;
	font-weight: 700;
}
