.case-showreel {
}
#showreelSection {
	[class^="col"] {
		margin-bottom: 1em;
	}
}

.case-teaser {
	text-decoration: none;
	white-space: initial;
	scale: 1;
	transition: filter 0.5s ease-out;
	margin-bottom: 1.25rem;

	&__image-box {
		position: relative;
		border-radius: calc(0.5rem + 0.5vw);
		width: 100%;
		aspect-ratio: 4/3;
		overflow: hidden;
		transform-origin: center center;
		transition: translate 0.3s ease-out;

		img {
			position: absolute;
			object-fit: cover;
			transform-origin: center center;
			margin: 0;
			transition: scale 0.2s ease-out;
		}
	}

	&__content {
		p {
			color: $honey;
			font-size: calc(0.75rem + 0.5vw);
			margin-top: 0.375vw;
			margin-bottom: 0.25rem;
		}

		h2 {
			color: $bone;
			font-size: calc(1.25rem + 0.5vw);
			margin-bottom: 0;
			margin-top: 0;
			text-wrap: balance;
			text-decoration: underline transparent;
			text-underline-offset: -0.1em;
			transition: text-decoration 0.25s, text-underline-offset 0.25s;
		}
	}
}

.case-teaser:hover {
	.case-teaser__image-box {
		img {
			scale: 1.1;
		}
	}

	.case-teaser__content {
		h2 {
			text-decoration-color: rgba($bone, 1);
			text-underline-offset: 0.1em;
		}
	}
}
