.lottie-oxybot-moving-head {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	height: auto;
	z-index: -1;
	display: none;
	@include bp("sm") {
		display: block;
	}
}

#frontAnimation {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0.2;
	width: 90%;
	z-index: -1;
	transform: translateY(-10%);
	@include bp("sm") {
		opacity: 1;
		width: 40%;
	}

	svg {
		width: 175% !important; // Lottie inline svg override
	}
}

.doggy {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	@include bp("sm") {
		width: 50%;
	}
}

[id^="lottie"] {
	& > * {
		height: auto;
		width: 100%;
	}
}

#lottieHeartWrapper {
	position: absolute;
	height: auto;
	top: 0%;
	left: 110vw;
	width: 20vw;
	rotate: 10deg;
	@include bp("sm") {
		top: 15%;
	}
	@include bp("lg") {
		top: 10%;
	}
}

#lottieLockWrapper {
	position: absolute;
	height: auto;
	top: 0%;
	left: 5vw;
	width: 18vw;
	@include bp("sm") {
		width: 16vw;
		left: 8vw;
	}
	@include bp("md") {
		left: 4vw;
		width: 12vw;
	}
	@include bp("lg") {
		width: 10vw;
		left: 8vw;
		top: 10%;
	}
}

#lottieStarsWrapper {
	position: absolute;
	height: auto;
	top: 15%;
	left: -7vw;
	width: 15vw;
	@include bp("lg") {
		top: 22%;
		width: 8vw;
	}
	@include bp("xl") {
		top: 15%;
		width: 8vw;
	}
}

#lottieTrueFalseWrapper {
	position: absolute;
	height: auto;
	top: 82%;
	left: 74vw;
	width: 20vw;
	@include bp("lg") {
		left: 45vw;
		top: 65%;
		width: 8vw;
	}
}

#lottieCryptoKeyWrapper {
	position: absolute;
	height: auto;
	top: 15%;
	width: 16vw;
	left: 110vw;
	@include bp("sm") {
		width: 13vw;
	}
	@include bp("lg") {
		left: 119vw;
		width: 10vw;
	}
	@include bp("xl") {
		width: 8vw;
	}
}

#lottieProtoLineWrapper {
	position: absolute;
	top: 30%;
	height: auto;
	right: -8%;
	width: 40vw;
	@include bp("lg") {
		width: 28vw;
	}
}

#lottieLiquid {
	position: sticky;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transition: opacity 0.25s;
	pointer-events: none;

	&.hide {
		opacity: 0;
	}

	svg {
		min-height: 100vh;
		min-width: 100vw;
		width: auto !important;
		height: auto !important;

		path {
			fill: transparent;
		}
	}

	&.sectionRovdjur,
	&.sectionErsta,
	&.sectionCivil {
		display: block;
	}

	&.sectionRovdjur svg path {
		fill: $tastelessMint;
	}
	&.sectionErsta svg path {
		fill: $chewedGum;
	}
	&.sectionCivil svg path {
		fill: $milkyHoney;
	}
}

#caseListing {
	&.sectionRovdjur {
		background-color: $tastelessMint;
	}
	&.sectionErsta {
		background-color: $chewedGum;
	}
	&.sectionCivil {
		background-color: $milkyHoney;
	}
}

[class^="illu-"] {
	height: auto;
	position: absolute;
}

.illu-letter-a {
	width: 15vw;
	bottom: 8%;
	left: 20vw;
	@include bp("sm") {
		left: 36vw;
		bottom: 12%;
	}
}

.illu-server {
	top: 80%;
	width: 26vw;
	left: 85vw;
	@include bp("sm") {
		top: 74%;
		width: 16vw;
		left: 85vw;
	}
}

.illu-hexagons {
	left: 30vw;
	bottom: 10%;
	width: 24vw;
	@include bp("sm") {
		width: 18vw;
	}
	@include bp("lg") {
		width: 14vw;
		bottom: 4%;
		left: 80vw;
	}
}

.section {
}

.section-image {
	img,
	svg {
		display: block;
		margin: 0 auto;
		height: auto;
		width: 75%;
		@include bp("sm") {
			width: 100%;
		}
	}

	.mobile-prototype--desktop {
		display: none;
		@include bp("sm") {
			display: block;
		}
	}

	.mobile-prototype--mobile {
		@include bp("sm") {
			display: none;
		}
	}

	.code-editor {
		@include bp("sm") {
			margin-top: -8rem;
		}
	}
}
