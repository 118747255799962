.fadeup {
	opacity: 0;
	transition-property: transform, opacity;
	transition-duration: 0.75s;
	transition-timing-function: ease-out;
	transition-timing-function: cubic-bezier(0.275, -0.425, 0.2, 1); /* custom */
	transform: translateY(30%) rotate(5deg) skewX(10deg);

	&.in-view {
		transform: none;
		opacity: 1;
		transform: translateY(0) rotate(0deg) skewX(0deg);
	}
}

@mixin transitionFlowUp($duration, $delay, $timing) {
	opacity: 0;
	transition: all $duration $delay $timing;
	transform: translateY(30%) rotate(5deg) skewX(10deg);

	&.in-view {
		transform: none;
		opacity: 1;
	}
}

.flow-in {
	@include transitionFlowUp(0.75s, 0s, cubic-bezier(0.275, -0.425, 0.2, 1));
}

// Ex: @include fadeIn(1s, .25s, ease-out);
@mixin fadeIn($duration, $delay, $timing) {
	opacity: 0;
	transition: all $duration $delay $timing;

	&.in-view {
		opacity: 1;
		transform: none;
	}
}

.fade-in {
	@include fadeIn(0.75s, 0.5s, ease-out);
}

.fade-in--left {
	@include fadeIn(0.75s, 0.5s, ease-out);
	transform: translateX(-5%);
}

.fade-in--right {
	@include fadeIn(0.75s, 0.5s, ease-out);
	transform: translateX(5%);
}

.fade-in--top {
	@include fadeIn(0.75s, 0.5s, ease-out);
	transform: translateY(-5%);
}

.fade-in--bottom {
	@include fadeIn(0.75s, 0.5s, ease-out);
	transform: translateY(5%);
}
