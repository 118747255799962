@mixin block() {
	.block-headline {
		color: $bone;
		font-family: $rocGroteskWide;
		font-weight: 600;
		font-size: calc(0.875rem + 2vw);
	}
}

.block-md {
	@include block();
	margin-bottom: calc(1.5rem + 2.5vw);
}

.block-lg {
	@include block();
	margin-bottom: calc(6em + 4.25vw);
}

[class^="col"] {
	&:last-child {
		margin-bottom: 0;
	}

	& > *:first-child {
		margin-top: 0;
	}

	& > p:last-child,
	& > img:last-child {
		margin-bottom: 0;
	}
}

.page .block-lg {
	border-top: 1px solid rgba($bone, 0.075);
	box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.5);
	padding: calc(2em + 1vw) 0 calc(2em + 1vw);
	//background-color: rgba($bone, 0.05);
	margin-top: calc(2em + 2vw);
	margin-bottom: 0;

	h2,
	h3 {
		margin-top: 0;
		margin-bottom: 0.75em;
	}
}
