.breadcrumbs {
	list-style: none;
	margin: 0;
	padding-top: 0.75em;

	[class^="col-"] {
		margin-bottom: 0;
	}

	li {
		display: inline-block;
		font-size: calc(0.875rem + 0.25vw);
		padding: 0;
		color: rgba($bone, 0.5);

		&:after {
			content: "";
			height: 0.5em;
			width: 0.5em;
			border-top: 1px solid rgba($bone, 0.5);
			border-right: 1px solid rgba($bone, 0.5);
			transform: rotate(45deg);
			display: inline-block;

			margin: 0 0.25rem;
		}

		&:first-child:after {
			content: "|";
			margin: 0 0.125rem 0 0.375rem;
			transform: rotate(0);
			border: none;
		}

		&:last-child {
			pointer-events: none;
			color: rgba($bone, 0.5);
			text-decoration: none;

			&:after {
				display: none;
			}
		}

		&:before {
			display: none;
		}

		a {
			color: $bone;

			&:after {
				display: none;
			}
		}
	}
}

.landing-analys-strategi,
.category-analysis {
	.breadcrumbs li:last-child {
		color: rgba($honey, 0.5);
	}
}

.category-analysis .breadcrumbs li:nth-last-child(2) a {
	color: $honey;
}

.landing-design,
.category-design {
	.breadcrumbs li:last-child {
		color: rgba($gum, 0.5);
	}
}

.category-design .breadcrumbs li:nth-last-child(2) a {
	color: $gum;
}

.landing-webbutveckling,
.category-development {
	.breadcrumbs li:last-child {
		color: rgba($grape, 0.5);
	}
}

.category-development .breadcrumbs li:nth-last-child(2) a {
	color: $grape;
}

.landing-seo,
.category-seo {
	.breadcrumbs li:last-child {
		color: rgba($mint, 0.5);
	}
}

.category-seo .breadcrumbs li:nth-last-child(2) a {
	color: $mint;
}

.landing-drift-support,
.category-service {
	.breadcrumbs li:last-child {
		color: rgba($lime, 0.5);
	}
}

.category-service .breadcrumbs li:nth-last-child(2) a {
	color: $lime;
}
