@mixin button($bg, $color) {
	background-color: $bg;
	color: $color;
	display: inline-block;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	font-family: $ibmPlexSans;
	font-weight: 500;
	font-weight: bold;
	text-align: center;
	width: 100%;
	border-radius: 10vw;
	border-style: solid;
	border-width: calc(0.16rem + 0.05vw);
	transition: 0.5s;
	font-size: calc(1rem + 0.25vw);
	margin-top: calc(0.5rem + 0.5vw);
	padding: calc(0.5rem + 0.125vw) calc(1rem + 0.25vw) calc(0.5rem + 0.125vw);
	@include bp("sm") {
		width: auto;
	}
}

.button {
	@include button($gum, $night);
	border-color: $gum;

	&:hover {
		animation-name: hueRotate;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		background-color: rgba($gum, 0.15);
		color: $gum;
		filter: saturate(2);
		box-shadow: 0 2px 0 $night;
	}
}

.button--night-outlined {
	border-color: $night;
	background: transparent;
	color: $night;

	&:focus,
	&:active {
		outline: none;
		mix-blend-mode: normal;
		background-color: darken($night, 10%);
	}

	&:hover {
		background-color: $night;
		color: $bone;
		animation: none;
		box-shadow: none;
	}
}

@keyframes hueRotate {
	0% {
		color: $gum;
		border-color: $gum;
		background-color: rgba($gum, 0.15);
	}
	50% {
		color: $mint;
		border-color: $mint;
		background-color: rgba($mint, 0.15);
	}
	100% {
		color: $gum;
		border-color: $gum;
		background-color: rgba($gum, 0.15);
	}
}

.link-group {
	text-align: center;
	@include bp("md") {
		text-align: left;
	}

	a {
		display: inline-block;
		margin-bottom: calc(1rem + 0.5vw);
		margin-right: calc(1rem + 0.5vw);
		margin-top: 0;

		@include bp("md") {
			display: inline;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
