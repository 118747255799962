$columns: 12;

.container {
	display: grid;
	column-gap: 2.5vw;
	row-gap: 2.5vw;
	grid-template-columns: repeat($columns, 1fr);
	grid-auto-flow: row dense;
	align-items: stretch;
	justify-content: center;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	width: 100%;
	@include bp("md") {
		column-gap: 1.5vw;
		padding-left: 10vw;
		padding-right: 10vw;
	}
	@include bp("lg") {
		column-gap: 1.5vw;
		padding-left: 12vw;
		padding-right: 12vw;
	}
}

.container {
	&:has(.nav__first-level) {
		position: relative;
		grid-template-columns: 1fr;
		@include bp("lg") {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&[class*="cols-"] {
		grid-template-columns: 1fr;
	}

	@include bp("sm") {
		& .cols-sm-1 {
			grid-template-columns: 1fr;
		}
		&.cols-sm-2 {
			grid-template-columns: repeat(2, 1fr);
		}
		&.cols-sm-3 {
			grid-template-columns: repeat(3, 1fr);
		}
		&.cols-sm-4 {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	@include bp("md") {
		&.cols-md-1 {
			grid-template-columns: 1fr;
		}
		&.cols-md-2 {
			grid-template-columns: repeat(2, 1fr);
		}
		&.cols-md-3 {
			grid-template-columns: repeat(3, 1fr);
		}
		&.cols-md-4 {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	@include bp("lg") {
		&.cols-lg-1 {
			grid-template-columns: 1fr;
		}
		&.cols-lg-2 {
			grid-template-columns: repeat(2, 1fr);
		}
		&.cols-lg-3 {
			grid-template-columns: repeat(3, 1fr);
		}
		&.cols-lg-4 {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

[class*="col-"] {
	grid-column: 1 / 13;
}

@each $name, $width in $breakpoints {
	@for $i from 1 through $columns {
		@for $k from 1 through $columns {
			$last: $k + 1;

			@if $i == $k {
				.col-#{$name}-#{$i} {
					@include bp(#{$name}) {
						grid-column: #{$i} / #{$last};
					}
				}
			} @else if ($i < $k) {
				.col-#{$name}-#{$i}-#{$k} {
					@include bp(#{$name}) {
						grid-column: #{$i} / #{$last};
					}
				}
			}
		}
	}
}

.first-xxs {
	@include bp("xxs") {
		grid-column-start: 1;
		grid-row-start: 1;
	}
}
.last-xxs {
	@include bp("xxs") {
		grid-row-start: 12;
	}
}
.first-xs {
	@include bp("xs") {
		grid-row-start: 1;
	}
}
.last-xs {
	@include bp("xs") {
		grid-row-start: 12;
	}
}
.first-sm {
	@include bp-max("sm") {
		grid-column-start: 1;
		grid-row-start: 1;
	}
}
.last-sm {
	@include bp("sm") {
		grid-row-start: 12;
	}
}
.first-md {
	@include bp("md") {
		grid-row-start: 1;
	}
}
.last-md {
	@include bp("md") {
		grid-row-start: 12;
	}
}
.first-lg {
	@include bp("lg") {
		grid-row-start: 1;
	}
}
.last-lg {
	@include bp("lg") {
		grid-row-start: 12;
	}
}
.first-xl {
	@include bp("xl") {
		grid-row-start: 1;
	}
}
.last-xl {
	@include bp("xl") {
		grid-row-start: 12;
	}
}

.order-default-xxs {
	@include bp("xxs") {
		grid-row-start: auto;
	}
}
.order-default-xs {
	@include bp("xs") {
		grid-row-start: auto;
	}
}
.order-default-sm {
	@include bp("sm") {
		grid-row-start: auto;
	}
}
.order-default-md {
	@include bp("md") {
		grid-row-start: auto;
	}
}
.order-default-lg {
	@include bp("lg") {
		grid-row-start: auto;
	}
}
.order-default-xl {
	@include bp("xl") {
		grid-row-start: auto;
	}
}
