@mixin block() {
	.block-headline {
		color: $bone;
		font-family: $rocGroteskWide;
		font-weight: 600;
		font-size: calc(0.875rem + 2vw);
	}
}

.block-md {
	@include block();
	margin-bottom: calc(1.5rem + 2.5vw);
}

.block-lg {
	@include block();
	margin-bottom: calc(6em + 4.25vw);
}

[class^="col"] {
	&:last-child {
		margin-bottom: 0;
	}

	& > *:first-child {
		margin-top: 0;
	}

	& > p:last-child,
	& > img:last-child {
		margin-bottom: 0;
	}
}
