blockquote {
    font-size: 1.5rem;
    font-weight: bold;
    position: relative;
    @include fluid( margin-top, 4rem, 5rem, $xs, $xl);
    @include fluid( margin-bottom, 3rem, 4rem, $xs, $xl);

    figcaption {
        font-size: 1rem;
        font-weight: normal;
    }
}
