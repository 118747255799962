article {
	position: relative;

	.landing-headline {
		@include bp("lg") {
			letter-spacing: -0.025em;
			font-size: 6vw;
		}
	}

	.landing-header {
		display: flex;
		position: relative;
		align-items: center;
		padding-top: 2em;
		min-height: 18vw;

		[class^="col-"] {
			margin-bottom: 0;
			position: relative;
		}

		&__image {
			position: absolute;
			width: 100%;
			right: 0;
			height: 100%;
			//z-index: -1;

			img {
				display: none;
				position: absolute;
				right: 0;
				top: calc(50% + 1em);
				transform: translateY(-50%);
				height: auto;
				width: auto;
				max-width: 125%;
				z-index: -1;
				@include bp("sm") {
					display: block;
				}
			}
		}

		p.lead {
			font-size: calc(1.25rem + 0.25vw);
			font-weight: 100;
			padding-bottom: 0;
		}
	}

	h1,
	h2,
	h3,
	h4,
	p,
	ul,
	ol {
		position: relative;

		&:before {
			font-family: roc-grotesk-wide, sans-serif;
			@include fluid(font-size, 0.75rem, 1rem, $xs, $xl);
			color: lighten($night, 20%);
			position: absolute;
			font-weight: 300;
			text-align: right;
			border-right: 1px solid;
			width: 1rem;
			writing-mode: vertical-rl;
			text-align: left;
			transition: all 0.5s 0.75s ease-out;
			opacity: 0;
			transform-origin: top right;
			transform: rotate(50deg) translateX(-2.3rem);
			@include bp("sm") {
				transform: rotate(50deg) translateX(-3rem);
				width: 1.75rem;
			}
			@include bp("md") {
				transform: rotate(50deg) translateX(-3.3rem);
				width: 2rem;
			}
			@include bp("lg") {
				transform: rotate(50deg) translateX(-3.5rem);
				width: 2.5rem;
			}
		}

		&.show {
			&:before {
				opacity: 1;
				transform: rotate(0deg) translateX(-2.3rem);
				@include bp("sm") {
					transform: rotate(0deg) translateX(-3rem);
					width: 1.75rem;
				}
				@include bp("md") {
					transform: rotate(0deg) translateX(-3.3rem);
					width: 2rem;
				}
				@include bp("lg") {
					transform: rotate(0deg) translateX(-3.5rem);
					width: 2.5rem;
				}
			}
		}
	}

	h1:before {
		content: "H1";
		top: 0.75em;
		@include fluid(bottom, 0.5rem, 1.6rem, $xs, $xl);
		@include fluid(top, 0.1rem, 0.75rem, $xs, $xl);
	}

	h2:before {
		content: "H2";
		top: 0.3rem;
		bottom: 0.5rem;
		@include bp("sm") {
			top: 0.3rem;
		}
		@include bp("md") {
			top: 0.4rem;
		}
		@include bp("lg") {
			top: 0.1rem;
			bottom: 0;
		}
		@include bp("xl") {
			top: 0.5rem;
		}
	}

	h3:before {
		content: "H3";
		top: 0.3rem;
		bottom: 0.3rem;
		@include bp("sm") {
			top: 0.3rem;
		}
		@include bp("md") {
			top: 0.4rem;
		}
		@include bp("xl") {
			top: 0.5rem;
		}
	}

	h4:before {
		content: "H4";
		top: 0.3rem;
		bottom: 0.5rem;
		@include bp("sm") {
			top: 0.3rem;
		}
		@include bp("md") {
			top: 0.4rem;
		}
		@include bp("xl") {
			top: 0.5rem;
		}
	}

	p:before {
		content: "P";
		top: 0.65rem;
		bottom: 0.5rem;
	}

	ul:before {
		content: "UL";
		top: 0.5rem;
		bottom: 0.4rem;
	}

	svg {
		&#support {
			#light {
				fill: $gum;
			}
		}
	}

	img {
		margin: 0.5rem -1.5rem 1.25rem;
		width: 100vw;
		@include bp("sm") {
			width: 100%;
			margin: 0.5rem 0 1.25rem;
		}
	}

	&-meta {
		color: $mint;
	}

	&.case {
		.article-header {
			margin-top: 0;
		}
	}

	&.case-list {
		.teaser--case {
			margin-top: 6rem;
			margin-bottom: 6rem;

			& + .teaser--case {
				margin-top: 0;
			}
		}
	}
}

.lead {
	font-size: calc(1.25rem + 0.75vw);
	font-family: $ibmPlexSans;
	font-weight: 500;
	margin-bottom: 0;
	line-height: 1.33;
	padding-bottom: calc(1.5rem + 0.5vw);
}

.align-left,
.align-right {
	position: relative;
	width: 100vw;
	margin: 0.5rem -1.5rem 1.25rem;
	@include bp("sm") {
		margin: 0.5rem 0 2rem -1.5rem;
		float: right;
		width: calc(33% - 1rem);
	}

	img {
		width: 100%;
		margin: 0;
		@include bp("sm") {
			float: left;
			width: calc(50vw - 1rem);
		}
	}

	&--50 {
		@include bp("sm") {
			float: right;
			margin: 0.5rem 0 2rem 0rem;
			width: calc(50% - 1rem);
		}

		@include bp("lg") {
			margin: 0.5rem -75% 2rem 0rem;
			width: calc(100% - 1rem);
		}
	}
}

.align-left {
	@include bp("sm") {
		float: left;
		margin: 0.5rem 3rem 1rem 0;
	}

	img {
		@include bp("sm") {
			float: right;
		}
	}
}

.align-right {
	@include bp("sm") {
		float: right;
		margin: 0.5rem 0 2rem 2rem;
	}

	&:before {
		right: auto;
		left: 1rem;
	}
}

.case-categories {
	margin-top: 0;
	margin-bottom: 2rem;
}

.arrow-ellipse {
	position: absolute;
	aspect-ratio: 1/1;
	height: 6em;
	right: -3em;
	bottom: -3em;
	transform-origin: center center;
	transition: all 1s ease-out;

	svg {
		height: auto;
		width: 1.3em;
		position: absolute;
		top: 1.35em;
		left: 1.15em;
		transition: translate 0.25s ease-out;
	}

	&:before,
	&:after {
		content: "";
		position: absolute;
		aspect-ratio: 1/1;
		height: 100%;
		box-shadow: 0 0 0 1px rgba($bone, 0.1);
		border-radius: 50%;
		transition: scale 0.25s ease-out, transform 0.25s ease-out,
			height 0.4s 0.125s ease-in, background-color 0.4s ease-out;
		z-index: -1;
		transform-origin: center;
	}

	&:after {
		transform: translate(50%, 50%);
	}
}

@property --angle {
	syntax: "<angle>";
	inherits: true;
	initial-value: 0deg;
}

@keyframes bg-spin {
	0% {
		--angle: 0turn;
	}
	100% {
		--angle: 1turn;
	}
}

@mixin navCard($color, $headlineColor) {
	box-shadow: 0 0 0 1px rgba($color, 0.1);
	border-radius: 0.375em;
	background-color: rgba($color, 0.05);
	backdrop-filter: blur(0.25em);
	padding: 1em 1em 1.25em;
	text-decoration: none;
	color: $color;
	overflow: hidden;
	line-height: 1.5;
	transition: box-shadow 0.4s 0.1s;

	&:hover {
		box-shadow: 0 0 0 1px rgba($color, 0.2), 0 0 2em rgba($night, 0.5);

		&:after {
			opacity: 0.66;
			animation: bg-spin 4s 0.25s infinite linear;
			background-image: conic-gradient(
				from var(--angle),
				transparent,
				transparent 33%,
				$headlineColor 40%,
				transparent 47%,
				transparent 100%
			);
		}

		h2 {
			text-decoration: underline;
			text-underline-offset: 0.25em;
		}
		.arrow-ellipse {
			&:before,
			&:after {
				background-color: rgba($headlineColor, 0.05);
			}
			&:before {
				transform: translateX(-1em);
				height: 200%;
				height: 400%;
				scale: 6;
				box-shadow: none;
				@include bp("sm") {
					scale: 4;
				}
				@include bp("xl") {
					scale: 5;
				}
			}
			&:after {
				transform: translate(0);
				box-shadow: 0 0 0 1px rgba($headlineColor, 0.33);
			}

			svg {
				translate: 0.1em 0;

				path {
					stroke: $headlineColor;
				}
			}
		}

		.nav-card__image {
			img {
				transform: translate(-50%, -50%) scale(1.1);
			}
		}
	}

	&:after {
		content: "";
		border-radius: 0.375em;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: opacity 0.5s 0.25s;
		animation: bg-spin 3s 0.25s infinite linear;
		background-image: conic-gradient(
			from var(--angle),
			transparent 0%,
			transparent 45%,
			$headlineColor 50%,
			transparent 55%,
			transparent 100%
		);

		clip-path: polygon(
			0% 0%,
			100% 0%,
			100% 100%,
			0% 100%,
			0% 0%,
			0.05em 0.05em,
			0.05em calc(100% - 0.05em),
			calc(100% - 0.05em) calc(100% - 0.05em),
			calc(100% - 0.05em) 0.05em,
			0.05em 0.05em,
			0.05em 0.05em
		);
	}

	h2 {
		color: $headlineColor;
		font-weight: 600;
		font-size: calc(0.875em + 0.1vw);
		margin-bottom: 0.5em;
		margin-top: 0;
		transition: 0.25s ease-out;
		text-underline-offset: 0;
	}
	p {
		font-size: calc(0.875rem + 0.1vw);
		font-family: $ibmPlexSans;
		font-weight: 300;
		width: calc(100% - 2.5rem);
		text-wrap: wrap;
		margin-bottom: 0;
		opacity: 0.8;
	}

	&:active {
		.arrow-ellipse {
			&:before,
			&:after {
				scale: 10;
				border: none;
			}
		}
	}

	.arrow-ellipse {
		svg path {
			stroke: $color;
		}
	}

	.tags {
		margin-bottom: 0.75em;
		.tag {
			font-weight: 400;
			color: rgba($bone, 0.5);
		}
	}

	.nav-card__image {
		position: relative;
		background-color: $color;
		aspect-ratio: 2.4/1;
		width: calc(100% + 2em);
		margin-left: -1em;
		margin-top: -1em;
		margin-bottom: 1em;
		overflow: clip;

		img {
			position: absolute;
			margin: 0;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transform-origin: center center;
			object-fit: cover;
			transition: transform ease-in-out 0.6s;
		}
	}
}

.nav-cards {
	margin-top: 32px;
	margin-top: calc(1rem + 0.75vw);
	margin-bottom: calc(3rem + 4vw);
}

.nav-card-- {
	@include navCard($bone, $bone);

	.nav-card__image {
		background: transparent;
	}
}

.nav-card {
	&--bone {
		@include navCard($bone, $bone);
	}
	&--honey {
		@include navCard($milkyHoney, $honey);
	}
	&--milkyHoney {
		@include navCard($milkyHoney, $milkyHoney);
	}
	&--gum {
		@include navCard($chewedGum, $gum);
	}
	&--chewedGum {
		@include navCard($chewedGum, $chewedGum);
	}
	&--grape {
		@include navCard($stompedGrape, $grape);
	}
	&--stompedGrape {
		@include navCard($stompedGrape, $stompedGrape);
	}
	&--mint {
		@include navCard($tastelessMint, $mint);
	}
	&--tastelessMint {
		@include navCard($tastelessMint, $tastelessMint);
	}
	&--lime {
		@include navCard($squeezedLime, $lime);
	}
	&--squeezedLime {
		@include navCard($squeezedLime, $squeezedLime);
	}
}

.tags {
	display: flex;
	flex-direction: row;
	gap: 0.25em;

	.tag {
		border-radius: 0.125em;
		margin-top: -0.1em;
		color: $night;
		font-size: calc(0.875rem + 0.1vw);
		line-height: 1;
		font-family: $ibmPlexSans;
		font-weight: 500;
	}
}

.bento-container {
	column-gap: 0.75em;
	row-gap: 0.75em;

	[class*="col-"] {
		margin-bottom: 0;
	}
}

.alt-caption {
	position: relative;
	margin-bottom: 1em;

	a:has(+ span) {
		img {
			margin-bottom: 0.25rem;
		}
	}

	span {
		display: inline-block;
		line-height: 1.375;
		font-size: 0.75em;
	}

	lottie-player {
		max-width: 100%;
		height: auto !important;
		overflow: clip;
	}

	img,
	lottie-player {
		@include bp("sm") {
			border-radius: 0.5rem;
		}
	}
}

.all-articles-link {
	float: right;
	font-size: calc(0.75rem + 0.25vw);
	padding: 1em;
}
