$bone: #edeae3;
$night: #1b1e2e;
$honey: #f3be68;
$gum: #ffa8c1;
$grape: #bdbdff;
$mint: #85e0dd;
$lime: #ced161;
$milkyHoney: #f9e9c7;
$chewedGum: #ffe3eb;
$chewedGum: #ffdbe6;
$stompedGrape: #e5e5ff;
$tastelessMint: #d0f6f0;
$squeezedLime: #eeefc8;
$oldBone: color-mix(in srgb, $night 20%, $bone);

$colors: ("bone", $bone), ("night", $night), ("gum", $gum), ("grape", $grape),
	("mint", $mint), ("honey", $honey), ("chewedGum", $chewedGum),
	("stompedGrape", $stompedGrape), ("tastelessMint", $tastelessMint),
	("milkyHoney", $milkyHoney);

@each $color in $colors {
	.bg-#{nth($color,1)} {
		background-color: nth($color, 2);
	}
}
