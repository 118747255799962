.top-image {
	position: relative;
	@include fluid(height, 16rem, 28rem, $xs, $xl);
	@include fluid(padding-bottom, 20rem, 28rem, $xs, $xl);
	@include fluid(margin-top, 2rem, 3.5rem, $xs, $xl);
	@include fluid(margin-bottom, 1.5rem, 4rem, $xs, $xl);

	width: 100vw;
	margin-left: -1.5rem;
	@include bp("sm") {
		margin-left: -2rem;
	}
	@include bp("md") {
		margin-left: -3rem;
	}
	@include bp("lg") {
		margin-left: 0;
		width: 81vw;
	}

	.image-wrapper {
		position: absolute;
		overflow: hidden;
		height: 100%;
		width: 100%;

		img {
			position: absolute;
			width: 100%;
			height: auto;
			min-height: 100%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin-left: 0;
			object-fit: cover;
		}
	}
}

.page-header:has(.service-page-header) {
	margin-top: 1em;
}

.service-page-header {
	margin-bottom: 1.5em;
	padding: 1.5em 1.5em 10em;
	border-radius: 0.5em;
	background: $grape;
	width: 100%;
	position: relative;
	overflow: clip;
	@include bp("sm") {
		min-height: 18em;
		min-height: none;
		padding: 3em 3em;
	}

	h1 {
		color: $night;
		font-size: calc(1.5rem + 2vw);
		line-height: 1.125;
		letter-spacing: -0.025em;
		margin: 0;
		@include bp("sm") {
			width: 60%;
		}
	}

	img {
		position: absolute;
		right: 0;
		//transform: translateX(-50%);
		bottom: 0;
		margin: 0;
		max-height: 11em;
		height: auto;
		width: auto;
		object-fit: contain;
		text-align: center;

		@include bp("sm") {
			transform: translateX(0);
			left: auto;
			height: 100%;
			width: auto;
			max-height: none;
		}
	}
}

.category-analysis .service-page-header {
	background-color: $honey;
}
.category-design .service-page-header {
	background-color: $gum;
}
.category-development .service-page-header {
	background-color: $grape;
}
.category-seo .service-page-header {
	background-color: $mint;
}
.category-service .service-page-header {
	background-color: $lime;
}
