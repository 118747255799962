.testimonial {
	position: relative;
	margin: 0;
	border-radius: 1rem;
	border: 1px solid rgba($bone, 0.1);
	background: linear-gradient(
		308deg,
		rgba(237, 234, 227, 0.05) 0%,
		rgba(237, 234, 227, 0) 100%
	);
	box-shadow: inset 0 0 1px 0 $night,
		0px 4px 16px 0px var(--Color-Opacity-night-20, rgba(27, 30, 46, 0.2));
	padding: 1.5rem 1rem 1rem 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&:before {
		content: "“";
		position: absolute;
		top: 0.75rem;
		left: 1.25rem;
		font-size: 3rem;
		color: $honey;
		font-family: $rocGroteskWide;
		font-weight: 700;
	}

	&__text {
		font-family: $ibmPlexSans;
		font-size: calc(1rem + 0.375vw);
		font-style: italic;
		padding-left: 2rem;
		padding-right: 0.5rem;
	}

	&__meta {
		margin-top: auto;

		p {
			float: left;
			font-size: calc(0.75rem + 0.375vw);
			color: rgba($bone, 0.8);
			font-weight: 300;
			line-height: 1.25;
			margin-bottom: 0;
			position: relative;
			top: 50%;
			transform: translateY(-50%);

			span {
				font-weight: 600;
				color: $honey;
				display: inline-block;
				margin-bottom: 0.25rem;
			}
		}
		img {
			float: right;
			margin-left: 1rem;
			width: calc(4rem + 1vw);
			aspect-ratio: 1/1;
			background: $honey;
			border-radius: 0.5rem;
		}
	}
}

.core-values {
	margin: 1rem 0;
	@include bp("lg") {
		margin: 0;
	}
}

.core-value {
	display: inline-block;
	width: 100%;
	margin-bottom: 1.5rem;
	&:last-of-type {
		margin-bottom: 0;
	}

	img {
		float: left;
		width: calc(3rem + 2vw);
		aspect-ratio: 1/1;
		margin-right: 1rem;
	}
	h2 {
		line-height: 1;
		margin-top: 0;
		margin-bottom: 0.25rem;
		font-family: $rocGroteskWide;
		font-weight: 600;
		font-size: calc(1rem + 1vw);
	}
	p {
		margin: 0;
	}
}
