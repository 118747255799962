.hide-content {
	display: none !important;
}
body.quote {
	position: relative;
	text-shadow: 0 1px 0 $night;
	min-height: 100dvh;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		min-height: 100dvh;
		background-image: url(/assets/images/glowing-rock2.webp);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: bottom center;
		z-index: -1;
		opacity: 0.25;
	}

	.quote-page {
		border: 1px solid rgba($night, 0.1);
		border: 1px solid rgba($bone, 0.1);
		//box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
		//aspect-ratio: 1/1.414; // A4
		margin-top: 2rem;
		border-radius: 1rem;
		margin-bottom: 2rem;
		overflow: clip;

		h1,
		h2,
		h3,
		h4 {
			margin-top: 1em;
		}

		p {
		}

		&__date {
			padding: 1rem 1.5rem;
			@include fluid(padding-top, 0.75rem, 1rem, $xs, $xl);
			@include fluid(padding-bottom, 0.75rem, 1rem, $xs, $xl);
			@include fluid(padding-left, 0.75rem, 1.5rem, $xs, $xl);
			@include fluid(padding-right, 0.75rem, 1.5rem, $xs, $xl);
			position: relative;
			border-bottom: 1px solid rgba($bone, 0.1);
			font-size: calc(0.875rem + 0.1vw);
			text-align: right;
			backdrop-filter: blur(1em);

			svg {
				position: absolute;
				@include fluid(left, 1rem, 1.5rem, $xs, $xl);
				top: 50%;
				transform: translateY(-50%);
				@include fluid(width, 3rem, 6.5rem, $xs, $xl);
				path {
					fill: $bone;
				}
			}
		}

		&__header {
			position: relative;
			padding: 3rem;
			@include fluid(padding-top, 1.5rem, 3rem, $xs, $xl);
			@include fluid(padding-bottom, 1.5rem, 3rem, $xs, $xl);
			@include fluid(padding-left, 1rem, 4rem, $xs, $xl);
			@include fluid(padding-right, 1rem, 4rem, $xs, $xl);
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			overflow: clip;
			border-bottom: 1px solid rgba($bone, 0.1);
		}

		&__meta,
		&__footer {
			@include fluid(padding-top, 0.75rem, 1rem, $xs, $xl);
			@include fluid(padding-bottom, 0.75rem, 1rem, $xs, $xl);
			@include fluid(padding-left, 1rem, 4rem, $xs, $xl);
			@include fluid(padding-right, 1rem, 4rem, $xs, $xl);
			position: relative;
			border-bottom: 1px solid rgba($bone, 0.1);
			font-size: calc(0.875rem + 0.1vw);
			margin-bottom: -1px;
			backdrop-filter: blur(1.5em);
		}

		&__meta {
			box-shadow: inset 0 -1px 0 rgba($bone, 0.1);
		}
		&__footer {
			@include fluid(padding-top, 0.75rem, 1rem, $xs, $xl);
			@include fluid(padding-bottom, 0.75rem, 1rem, $xs, $xl);
			@include fluid(padding-left, 0.75rem, 1.5rem, $xs, $xl);
			@include fluid(padding-right, 0.75rem, 1.5rem, $xs, $xl);
			text-align: center;
			@include bp("sm") {
				text-align: left;
			}

			div {
				width: 100%;
				@include bp("sm") {
					width: auto;
					float: right;
				}
			}
		}

		main {
			//background: rgba($night, 0.6);
			@include fluid(padding-top, 0.75rem, 3rem, $xs, $xl);
			@include fluid(padding-bottom, 0.75rem, 3rem, $xs, $xl);
			@include fluid(padding-left, 1rem, 4rem, $xs, $xl);
			@include fluid(padding-right, 1rem, 4rem, $xs, $xl);
			backdrop-filter: blur(1.5em);
			border-bottom: 1px solid rgba($bone, 0.1);

			*:first-child {
				margin-top: 0;
			}
		}

		&__footer {
		}

		h1 {
			@include fluid(font-size, 2rem, 5rem, $xs, $xl);
			margin: 0;
			text-wrap: balance;
		}
		h2 {
			@include fluid(font-size, 1.375rem, 1.75rem, $xs, $xl);
		}
		h3 {
			@include fluid(font-size, 1.25rem, 1.375rem, $xs, $xl);
		}
		h4 {
			@include fluid(font-size, 1.125rem, 1.125rem, $xs, $xl);
		}
		p {
			@include fluid(font-size, 0.875rem, 1.125rem, $xs, $xl);
		}

		table {
			@include fluid(font-size, 0.875rem, 1.125rem, $xs, $xl);
		}
	}
}

.quote-bg-blur {
	position: absolute;
	bottom: 0;
	z-index: -1;
}

.fredrick-signature {
	width: 12rem;
	height: auto;
}
