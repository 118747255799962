.site-footer {
	position: relative;
	margin-top: 0;

	&.section {
		min-height: auto;
		padding-bottom: 3em;
	}

	.doggy {
		display: none;
		height: auto;
		width: 40%;
		opacity: 0.4;
		margin-bottom: -40vw;
		z-index: -1;
		bottom: 100%;
		//pointer-events: none;
		mix-blend-mode: lighten;
		@include bp("sm") {
			right: 7.5%;
			opacity: 1;
			margin-bottom: 0;
		}
		@include bp("md") {
			display: block;
		}
		@include bp("lg") {
			//right: 15%;
		}
	}
}

.disco {
	mix-blend-mode: color-dodge;
	animation: disco 0.955s infinite steps(4, end);

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: $grape;
		mix-blend-mode: lighten;
		pointer-events: none;
		opacity: 0.25;
	}

	img,
	svg {
		mix-blend-mode: difference;
		animation: trippy 1s infinite linear;
	}

	.case-teaser {
		overflow: visible !important;
		img {
			-webkit-box-reflect: below
				linear-gradient(transparent, rgba(0, 0, 0, 0.5));
		}
	}

	footer {
		overflow: hidden;
	}

	h1,
	h2,
	h3,
	h4 {
		color: $mint;
		text-shadow: 0 0 1em $mint;
	}
	p {
		color: $gum;
		text-shadow: 0 0 1em $gum;
	}

	a {
		color: $lime;
		text-shadow: 0 0 1em $lime;
	}
	#frontAnimation * {
		mix-blend-mode: color-dodge;
		fill: $gum !important;
	}

	.logo-oxys svg,
	.doggy {
		mix-blend-mode: normal;
		animation: none;
	}

	.doggy {
		animation: nod 0.475s infinite steps(2, end);
		animation-delay: 0.475s;
		transform-origin: center bottom;
		transform: translateY(1.5em);
	}
}

.cookies-milk {
	position: relative;
	border: 1px solid rgba(243, 190, 104, 0.5);
	border-radius: 0.5rem;
	padding: 1em 1.5em;
	background-color: $night;
	box-shadow: 0 -1em 100px rgba($honey, 0.75);
	box-shadow: 0 -1em 100px rgba($night, 0.75);
	z-index: -1;
	padding-bottom: 6em;
	@include bp("md") {
		padding: 1.5em 2.25em 2em;
	}

	&:before {
		content: "";
		position: absolute;
		right: 0;
		aspect-ratio: 1/0.25;
		width: 50%;
		bottom: calc(100% + 1px);
		background: linear-gradient(
			0deg,
			rgba($night, 0.75) 0%,
			rgba($night, 0) 100%
		);

		border-radius: 50% 50% 0 0;
		//filter: blur(2em);
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		border-radius: 0 0 0.5rem 0.5rem;
		background: $honey;
		width: 100%;
		background: linear-gradient(165deg, rgba(2, 0, 36, 0) 50%, #f3be68 100%);

		@include bp("md") {
			background: linear-gradient(135deg, rgba(2, 0, 36, 0) 50%, $honey 100%);
			width: 50%;
			border-radius: 0 0.5rem 0.5rem 0;
		}
	}

	h2 {
		font-family: $rocGroteskWide;
		font-size: 1.5em;
		margin-top: 0;
		@include bp("md") {
			font-size: 2em;
			width: 60%;
		}
	}

	p {
		font-size: 0.85em;
		margin-bottom: 0;
		width: 60%;
		@include bp("md") {
			width: 40%;
		}
		@include bp("lg") {
			width: 50%;
		}
		@include bp("xl") {
			width: 75%;
		}
	}

	img:not(.doggy) {
		position: absolute;
		bottom: 0;
		right: 0;
		height: auto;
		width: 50%;
		border-radius: 0 0 0.5rem 0;
		margin-bottom: 0;
		//filter: drop-shadow(-4px -2px 0px $night);
		z-index: 1;

		@include bp("md") {
			min-height: calc(100% + 3.25rem);
			min-width: 20%;
			max-height: 150%;
			width: auto;
		}
	}
}

@keyframes disco {
	from {
		filter: hue-rotate(0deg) saturate(2);
	}
	to {
		filter: hue-rotate(360deg) saturate(2);
	}
}

@keyframes nod {
	to {
		scale: 1;
		rotate: 0;
	}
	from {
		scale: 1.2;
		rotate: -5deg;
	}
}

@keyframes trippy {
	0% {
		filter: hue-rotate(0deg) blur(0) contrast(0);
	}
	50% {
		filter: hue-rotate(180deg) blur(3em) contrast(40);
	}
	100% {
		filter: hue-rotate(360deg) blur(0) contrast(0);
	}
}
