.navigation-desktop {
	display: none;
	@include bp("lg") {
		display: block;
	}
	z-index: 5;
	transition: all 0.3s ease-in-out;
	position: static;
	border-bottom: 1px solid rgba($bone, 0.2);

	a {
		white-space: nowrap;
	}

	.nav__item {
		margin: 0;
		padding: 0;

		&:hover {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:before {
			display: none;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-repeat: no-repeat;
			background-position: left 1.75rem top 0.5rem;
			background-size: 2.5rem;
			z-index: -1;
		}

		&--analyze,
		&--design,
		&--dev,
		&--seo,
		&--support {
			& > .nav__link {
				padding-top: 1.5rem;
			}
		}
	}

	.nav__item--dropdown {
		& > .nav__link {
			&:after {
				content: " +";
				font-size: 2rem;
				line-height: 1rem;
				margin-left: 0.5rem;
				margin-top: -0.1em;
			}
		}

		&.megamenu {
			position: static;

			&:hover {
				border-bottom-left-radius: 0;
			}

			& > ul {
			}
		}

		&:hover {
			.nav__second-level {
				transform: scaleY(1);
				height: auto;
			}
		}
	}

	.nav__link {
		position: relative;
		line-height: 1;
		color: $bone;
		text-decoration: none;
		font-family: $ibmPlexSans;
		font-weight: 600;

		height: 100%;
		text-transform: uppercase;
		transition: all 0.3s ease-out;
		font-size: 1.1em;
		padding: 0 1em;

		&:hover {
			//background-color: rgba($night, 0.15);
		}

		&:has(img) {
			padding: 0.5rem 1rem 1rem;

			img {
				filter: brightness(1);
				height: 2.5rem;
				width: 2.5rem;
				translate: 0 0.5rem;
				margin-right: 0.75rem;
			}

			&:hover {
				img {
					filter: brightness(1.25);
				}
			}
		}
	}

	.nav__first-level {
		margin: 0;
		display: flex;
		list-style-type: none;
		flex-direction: row;
		margin-left: auto;
		height: 7.5rem;

		& > li {
			& > .nav__link {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}

		& > .nav__item:hover {
			background-color: $bone;

			.nav__link {
				color: $night;
			}

			.nav__second-level {
				max-height: 1000px;
				transform: scaleY(1);

				.nav__item {
					transform: translateX(0);
					opacity: 1;
					@for $i from 1 through 10 {
						&:nth-child(#{$i}) {
							transition-delay: $i * 0.05s;
						}
					}
				}

				& > .nav__item {
					& > a {
						&:before {
							content: "";
							position: absolute;
							bottom: 0.5rem;
							min-width: 0rem;
							height: 0.125rem;
							background-color: $night;
							transition: min-width 0.2s ease-out;
						}

						&:hover:before {
							min-width: calc(100% - 4rem);
						}
					}
				}
			}
		}
	}

	.nav__second-level {
		transform: scaleY(0);
		max-height: 0;
		position: static;
		background-color: $bone;
		list-style-type: none;
		min-width: calc(100% + 2px);
		left: -1px;
		border-radius: 0 0 0.5rem 0.5rem;
		overflow: hidden;
		margin: 0;
		transition: all 0.2s;
		transform-origin: top;
		z-index: 1;
		position: absolute;
		padding-bottom: 1.5rem;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		border-left: 1px solid $night;
		border-right: 1px solid $night;
		border-bottom: 1px solid $night;

		& > .nav__item {
			transform: translateX(2rem);
			opacity: 0;
			transition: all 0.3s ease-in-out;

			& > .nav__link {
				padding-top: 2rem;
				text-decoration: none;
			}
		}

		.nav__item {
			display: grid;
			align-self: flex-start;
		}

		.nav__link {
			color: $night;
			font-size: 1.25rem;
			text-transform: none;
			padding: 1rem 2rem;
		}

		&.expanded {
			max-height: 2000px;

			.nav__item {
				opacity: 1;
				transform: translateX(0);
				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						transition-delay: $i * 0.05s;
					}
				}
			}
		}
	}

	.nav__third-level {
		margin: 0;
		padding: 0;

		&:has(.nav__item:hover) {
			.nav__item {
				a {
					//opacity: 0.33;
					&:before {
						transform: translateX(100px);
					}
				}

				&:hover a {
					opacity: 1;
				}
			}
		}

		.nav__item {
			border: none;
			display: grid;
			align-self: flex-start;
		}

		.nav__link {
			border: none;
			font-size: 1rem;
			font-weight: 400;
			padding: 0.75rem 2rem;

			&:before {
				content: "»";
				margin-right: 0.5rem;
			}

			&:after {
				display: none;
			}
		}
	}
}
