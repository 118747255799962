.skip-link {
	background-color: rgba($gum, 0);
	border-color: rgba($gum, 0);
	color: rgba($night, 0);
	position: absolute;
	left: 50%;
	top: 0;
	transition: all 0.15s ease-out;
	transform: translate(-50%, -6rem);
	z-index: 1000;

	&:focus,
	&:active {
		background-color: rgba($gum, 1);
		border-color: rgba($gum, 1);
		color: rgba($night, 1);
		transform: translate(-50%, 4rem);
	}
}
