.logo-carousel {
	position: relative;
	overflow: hidden;

	.logos {
		aspect-ratio: 25.71/1;
		background: url(/assets/images/logo-carousel.png) repeat-x;
		width: 800vw;
		background-size: 50%;
		background-position: center center;
		animation: slide 32s linear infinite;

		@include bp("md") {
			width: 400vw;
		}

		@include bp("lg") {
			animation-duration: 24s;
		}
	}
}

@keyframes slide {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-50%, 0, 0);
	}
}
