@mixin section() {
	position: relative;
	padding-top: calc(5rem + 5vw);
	padding-bottom: calc(5rem + 5vw);

	@include bp("sm") {
		display: flex;
	}

	&__headline {
		&--lg {
			font-size: calc(1em + 2.5vw);
		}
		&--md {
			font-size: calc(1em + 1.5vw);
			letter-spacing: -0.02em;
		}
		&-sm {
		}
	}
}

#sectionClients {
	p {
		margin-bottom: revert;
	}
}

#sectionTestimonials {
	h1 {
		text-align: center;
	}
}

#sectionAboutUs {
	position: relative;

	&:before {
		display: none;
		content: "";
		position: absolute;
		aspect-ratio: 0.644/1;
		height: 25.5rem;
		background-image: url(/assets/images/oxybot-peak.png);
		background-repeat: no-repeat;
		background-size: cover;
		top: 50%;
		left: 0;
		transform: translateY(-60%);

		@include bp("lg") {
			display: block;
		}
	}

	.container {
		@include bp("md") {
			row-gap: 1.5vw;
		}
	}
}

#sectionServices {
	position: relative;
	overflow: visible;

	&:before {
		content: "";
		aspect-ratio: 1/1.4;
		position: absolute;
		width: 300%;
		background-size: 75%;
		background-image: url(/assets/images/services-blur-bg.png);
		background-repeat: no-repeat;
		background-position: center center;
		top: -60%;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: -1;
		opacity: 1;
		filter: blur(1rem);

		@include bp("sm") {
			width: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	[class^="section__headline"] {
		text-align: center;
		text-shadow: 0 0 1rem rgba($night, 0.5), 0 0.125rem 0 $night;

		& + p {
			text-align: center;
			text-shadow: 0 0 1rem rgba($night, 0.5), 0 0.0625rem 0 $night;
			font-weight: 500;
		}
	}

	.container:first-of-type {
		margin-bottom: calc(1em + 1vw);
	}

	.bento-container {
		margin-bottom: 0.75em;
	}

	[class^="nav-card"] {
		background-color: rgba($night, 0.6);
		backdrop-filter: blur(1rem);
		box-shadow: 0 0 0 1px rgba($night, 0.5);
	}
}

.section {
	@include section();

	&.case {
		padding-top: 0;
		padding-bottom: 0;
		height: auto;
		min-height: auto;
	}

	&--case {
		border-radius: 1vw;
		position: sticky;
		top: 2vw;
		color: $night;
		padding: 10vw 0;
		display: block;
		height: calc(100vh - 4vw);
		width: 96vw;
		margin-left: 2vw;
		margin-bottom: 8vw;
		overflow: hidden;
		// scroll-snap-align: start;

		@include bp("sm") {
			display: flex;
			padding-top: 4rem;
			padding-bottom: 4rem;
		}

		.container {
			position: relative;
			padding-left: 10vw;
			padding-right: 10vw;
		}

		img,
		picture {
			width: 100%;
			height: auto;
			object-fit: cover;
			position: absolute;
			bottom: 0;
			right: 0;
			border-radius: 0 1rem 1rem 0;
			object-position: 0 100%;
			@include bp("sm") {
				top: 0;
				bottom: auto;
				//max-width: 66%;
				height: 100%;
				width: auto;
				object-position: 0 50%;
			}
		}
	}

	//  INTRO
	&--intro {
		padding-top: 6dvh;
		@include bp("lg") {
			min-height: 100dvh;
		}

		.container {
			justify-self: center;
			align-self: center;
		}

		h1 {
			font-size: 12vw;
			margin-bottom: 2rem;
			@include fluid(margin-left, -0.2rem, -0.5rem, $xs, $xl);
			font-family: $rocGroteskWide;
			font-weight: 700;
			color: $bone;
			-webkit-text-fill-color: transparent;
			-webkit-text-stroke: 1px $bone;
			line-height: 1;
			@include bp("sm") {
				font-size: 8vw;
			}
			@include bp("lg") {
				line-height: 0.9;
			}
			@include bp("xl") {
				line-height: 0.85;
			}

			span {
				color: $bone;
				-webkit-text-fill-color: $bone;
			}
		}

		.intro-text {
			position: relative;
			display: block;
			color: $bone;
			margin-bottom: calc(1rem + 1vw);
			@include bp("sm") {
				width: 80%;
			}
			@include bp("lg") {
				width: 60%;
			}

			&:after {
				position: absolute;
				content: "";
				border-left: 0.5rem solid $bone;
				top: 0.4rem;
				height: calc(100% - 1rem);
				pointer-events: none;

				// The vw numbers must be same as container padding
				left: -8vw;
				width: calc(100% + 8vw);
				@include bp("lg") {
					left: -10vw;
					width: calc(100% + 10vw);
				}
				@include bp("lg") {
					left: -12vw;
					width: calc(100% + 12vw);
				}
			}
		}
	}
}

.section-small {
	@extend .section;
	padding-top: calc(2.5rem + 2.5vw);
	padding-bottom: calc(2.5rem + 2.5vw);
}

/*#caseListing {
	scroll-snap-type: y mandatory;
	overflow: scroll;
}*/
