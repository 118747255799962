#main {
	margin-top: 5rem;
	@include bp("lg") {
		margin-top: 0;
	}
}

.page--cases {
	.page-wrapper {
		& > .section:first-child {
			display: inline-block;
			min-height: auto;
			padding-top: 0;
			padding-bottom: calc(2.5rem + 2.5vw);
		}
	}
}

.page--frontpage {
	.logo-oxys svg path {
		fill: $bone;
	}

	.section .teaser-content h2:before {
		display: none;
	}
}

#main {
	overflow-y: auto;
	overflow-x: clip;
	position: relative;
	height: calc(100svh - 5rem);
	@include bp("lg") {
		height: calc(100svh - 7.5rem);
	}
}

.article-header,
.page-header {
	margin-top: 2em;

	& + .container [class^="col-"] h2:first-child {
		margin-top: 0;
	}

	h1 {
		margin-bottom: 0;
	}
}
