ul,
ol {
	position: relative;
	list-style: none;
	padding-left: 0;
	margin: 2rem 0;

	li {
		position: relative;
		margin-bottom: 0.75rem;
		position: relative;
		padding-left: 2rem;

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			position: absolute;
			top: 0.05em;
			left: -0.5em;
			font-size: 1em;
			font-weight: bold;
			height: 1.5em;
			width: 1.5em;
			background: $night;
			border-radius: 50%;
			border: 1px solid rgba($mint, 0.5);
			box-shadow: 0.1em 0.1em 0 rgba($mint, 0.5);
			text-align: center;
			line-height: 1.4;
		}

		&:not([class]) {
			color: $mint;
		}
	}
}

ul:not([class]) {
	li:not([class]) {
		padding-left: 3em;
		&:before {
			content: "";
			height: 0.5em;
			width: 0.5em;
			top: 0.375em;
			left: 1em;
			border-radius: 50%;
			border: 1px solid rgba($mint, 0.5);
			box-shadow: 3px 3px 0 rgba($mint, 0.5);
			background: $night;
			margin-right: 0.5em;
		}
	}
}

ol {
	counter-reset: ol-counter;

	li {
		&:before {
			content: "1";
			counter-increment: ol-counter;
			content: counter(ol-counter);
			font-size: 1.25rem;
		}
	}
}
