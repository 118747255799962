.navigation-mobile {
	z-index: 5;
	position: fixed;
	//overflow: auto;

	position: fixed;
	height: 5rem;
	width: 100%;
	border-radius: 0;
	box-shadow: none;
	//transform: translateX(100%);
	@include bp("lg") {
		display: none;
	}

	.navigation__top {
		background: $night;
		transition: background 0.5s;
		height: 5rem;
		border-bottom: 1px solid rgba($bone, 0.2);

		.logo-oxys {
			height: 100%;
			padding-left: 1.5rem;
		}
	}

	.navigation__main {
		background: $bone;
		transform: translateX(100%);
		transition: transform 0.5s;
		height: calc(100vh - 5rem);
		height: calc(100dvh - 5rem);
		overflow: auto;
	}

	.nav__item {
		margin: 0;
		padding: 0;

		&:hover {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:before {
			display: none;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-repeat: no-repeat;
			background-position: right -50px bottom -34px;
			background-size: 150px 150px;
			z-index: -1;
			@include bp("lg") {
				background-size: 2.5rem;
				background-position: left 1.75rem top 0.5rem;
			}
		}

		&--analyze,
		&--design,
		&--dev,
		&--seo,
		&--support {
			& > .nav__link {
				@include bp("lg") {
					padding-top: 1.5rem;
				}
			}
		}
	}

	.nav__item--dropdown {
		& > .nav__link {
			width: calc(100% - 5rem);
		}

		& > ul > li {
			background-color: rgba($night, 0.15);
		}

		&.megamenu {
			position: static;

			&:hover {
				border-bottom-left-radius: 0;
			}

			& > ul {
				@include bp("sm") {
					display: flex;
					flex-wrap: wrap;
					align-items: stretch;
					align-content: stretch;
				}

				& > li {
					align-self: stretch;
					flex: 1 0 33.3%;
				}
			}
		}
	}

	.nav__link {
		position: relative;
		display: block;
		padding: 1.5rem 1.5rem;
		line-height: 1;
		color: $night;
		text-decoration: none;
		font-family: $ibmPlexSans;
		font-weight: 600;
		font-size: 2rem;
		text-transform: uppercase;
		border-top: 1px solid $night;
		transition: all 0.3s ease-out;
		@include bp("lg") {
			border-top: none;
			font-size: 1.5rem;
			padding: 1rem 2rem 1rem 1.75rem;
		}

		&:hover {
			background-color: rgba($night, 0.15);
		}

		&:has(img) {
			padding: 1rem 4.5rem 1rem 1.25rem;

			img {
				filter: brightness(1);
				height: 2.5rem;
				width: 2.5rem;
				translate: 0 0.5rem;
				margin-right: 0.75rem;
			}

			&:hover {
				img {
					filter: brightness(1.25);
				}
			}
		}
	}

	.nav__first-level {
		margin: 0;
		display: flex;
		list-style-type: none;
		flex-direction: column;
		width: 100vw;
	}

	.nav__second-level {
		transform: scaleY(0);
		max-height: 0;
		position: static;
		background-color: $bone;
		list-style-type: none;
		min-width: calc(100% + 2px);
		left: -1px;
		border-radius: 0 0 0.5rem 0.5rem;
		overflow: hidden;
		margin: 0;
		padding-left: 1.5rem;
		transition: all 0.2s;
		transform-origin: top;
		z-index: 1;

		& > .nav__item {
			transform: translateX(2rem);
			opacity: 0;
			transition: all 0.3s ease-in-out;

			border-left: 6px solid $night;

			&:last-child {
				border-bottom: 1px solid $night;
			}
		}

		.nav__link {
			color: $night;
			font-size: 1.25rem;
			text-transform: none;
			text-decoration: underline;
			font-size: 1.5rem;
		}

		&.expanded {
			max-height: 2000px;

			transform: scaleY(1);
			margin-bottom: 2rem;

			.nav__item {
				opacity: 1;
				transform: translateX(0);
				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						transition-delay: $i * 0.05s;
					}
				}
			}
		}
	}

	.nav__third-level {
		margin: 0;
		padding: 0;

		.nav__item {
			border: none;

			&:last-child {
				padding-bottom: 1rem;
			}
		}

		.nav__link {
			border: none;
			font-size: 1rem;
			padding: 0.75rem 1.25rem 0.75rem 2rem;
			text-decoration: underline;

			&:after {
				display: none;
			}
		}
	}
}

.nav__expand-button {
	position: absolute;
	right: -1px;
	top: 0;
	height: 5rem;
	width: calc(5rem + 1px);
	border: 1px solid $night;
	border-bottom: 0;
	border-radius: 0;
	font-size: 4rem;
	padding: 0;
	background-color: transparent;
	cursor: pointer;
	overflow: hidden;
	@include bp("lg") {
		display: none;
	}

	&:hover {
		@include bp-max("lg") {
			background-color: rgba($night, 0.15);
		}
	}

	&.active {
		span {
			transform: rotate(135deg);
		}
	}

	span {
		display: block;
		transition: transform 0.3s ease-out;
		transform-origin: center center;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		line-height: 100%;
		@include bp("lg") {
			padding-left: 0;
			text-align: left;
			padding-top: 0.55rem;
		}
	}
}
