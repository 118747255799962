// settings
$remBase: 16; //set base font size. unitless value in pixels.
$widthMin: 480; //set small breakpoint. unitless value in pixels.
$widthMax: 1024; //set large breakpoint. unitless value in pixels.

@mixin font-size($valueMin, $valueMax, $widthMin, $widthMax) {
  // turn mixin parameters into rem value.
  $fontMin: $valueMin;
  $fontMax: $valueMax;
	
  // set minimum font size. give it rem value.
  font-size: $valueMin;
	
  // set font size between min and max breakpoints.
  @media (min-width: #{$widthMin}px) {
    font-size: calc( #{$fontMin} + (#{$fontMax} - #{$fontMin}) * ((100vw - #{$widthMin}) / (#{$widthMax} - #{$widthMin})));
  }

  // set font size above max breakpoint
  @media (min-width: #{$widthMax}px) {
    font-size: $valueMax;
  }
}

@mixin fluid($property, $min, $max, $min-vw: 768px, $max-vw: 1600px) {
    $min-no-unit: strip-units($min);
    $max-no-unit: strip-units($max);
    $min-vw-no-unit: strip-units($min-vw);
    $max-vw-no-unit: strip-units($max-vw);
  
    @if getUnit($min) == "rem" or getUnit($min) == "em" {
      $min-no-unit: strip-units($min) * 16;
      $max-no-unit: strip-units($max) * 16;
    }
    $responsive: calc(
      #{$min-no-unit}px + (#{$max-no-unit} - #{$min-no-unit}) *
        ((100vw - #{$min-vw}) / (#{$max-vw-no-unit} - #{$min-vw-no-unit}))
    );
  
    $average: $min + $max / 2;
    #{$property}: $average;
    #{$property}: $responsive;
  
    @media screen and (max-width: #{$min-vw}) {
      #{$property}: $min;
    }
    @media screen and (min-width: #{$max-vw}) {
      #{$property}: $max;
    }
  }
  @function strip-units($number) {
    @return $number / ($number * 0 + 1);
  }
  @function getUnit($value) {
    @return str-slice($value * 0 + "", 2, -1);
  }


/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin bp($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin bp-max($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint) - 1) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

/// Linear (smoooth) gradient mixin
  @mixin gradient($direction, $from, $to) {
    background-color: $to;
    background-image: linear-gradient($direction,
      rgba($from, 1) 0%,
      rgba($from, 0.738) 19%,
      rgba($from, 0.541) 34%,
      rgba($from, 0.382) 47%,
      rgba($from, 0.278) 56.5%,
      rgba($from, 0.194) 65%,
      rgba($from, 0.126) 73%,
      rgba($from, 0.075) 80.2%,
      rgba($from, 0.042) 86.1%,
      rgba($from, 0.021) 91%,
      rgba($from, 0.008) 95.2%,
      rgba($from, 0.002) 98.2%,
      rgba($from, 0) 100%
    )
  }