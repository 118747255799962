table {
	border-radius: 0.75rem;
	overflow: clip;
	width: 100%;
	text-align: left;
	border: 1px solid rgba($bone, 0.1);
	@include fluid(margin-bottom, 1rem, 2rem, $xs, $xl);
	border-collapse: collapse; /* Tar bort avstånd mellan cellkanter */
	border-spacing: 0; /* Tar bort mellanrum mellan celler */
	box-shadow: inset 0 0 0 1px rgba($bone, 0.1);

	td {
		border-bottom: 1px solid rgba($bone, 0.1);
		box-shadow: inset 0 -1px 0 0 rgba($bone, 0.1);
		min-width: 3rem;
		@include fluid(padding-top, 0.375rem, 0.75rem, $xs, $xl);
		@include fluid(padding-bottom, 0.375rem, 0.75rem, $xs, $xl);
		@include fluid(padding-left, 0.5rem, 1rem, $xs, $xl);
		@include fluid(padding-right, 0.5rem, 1rem, $xs, $xl);
	}

	tr {
		td:last-child,
		td:nth-last-of-type(2) {
			box-shadow: none;
		}
	}

	tfoot {
		border-radius: 0.75rem;
		overflow: clip;
		td {
			background: rgba($bone, 0.1);
			border: none;
			&:first-child {
				border-radius: 0 0 0 0.5rem;
			}
			&:last-child {
				border-radius: 0 0 0.5rem 0;
			}
		}
	}

	th:last-child,
	td:last-child {
		text-align: right;
	}
}
