#hamburger {
	position: fixed;
	left: calc(100% - 5rem);
	bottom: calc(100% - 5rem);
	height: 5rem;
	width: 5rem;
	cursor: pointer;
	transition: all 1s;
	z-index: 6;
	transition: none;
	overflow: hidden;
	@include bp("lg") {
		display: none;
	}

	&:hover {
		div {
			span {
				&:nth-child(1) {
					transform: translateY(-0.2rem);
				}
				&:nth-child(3) {
					transform: translateY(0.2rem);
				}
			}
		}
	}

	div {
		position: absolute;
		right: 1.4rem;
		top: 1.75rem;
		height: 1.25rem;
		width: 2rem;

		span {
			position: absolute;
			height: 2px;
			width: 100%;
			top: 0;
			left: 0;
			background-color: $bone;
			transition: all 0.3s;

			&:nth-child(2) {
				top: 50%;
				transform: translateY(-1px);
			}
			&:nth-child(3) {
				bottom: 0;
				top: auto;
			}
		}
	}
}

body.nav-open {
	.navigation-mobile {
		transform: translateX(0);
		opacity: 1;
		height: 100vh;
	}

	.navigation__top {
		background: $bone;
	}

	.navigation__main {
		transform: translateX(0);
	}

	#hamburger {
		div {
			span {
				background-color: $night;
				height: 3px;
				border-radius: 1.5px;

				&:nth-child(1) {
					transform: rotate(45deg) translate(3.5px, 0rem);
					left: 0.5rem;
					width: 75%;
				}
				&:nth-child(2) {
					transform: translateX(-25%);
					width: 125%;
				}
				&:nth-child(3) {
					bottom: -3px;
					transform: rotate(-45deg) translate(3.5px, 0rem);
					left: 0.5rem;
					width: 75%;
				}
			}
		}
	}

	.logo-oxys {
		svg path {
			@include bp-max("lg") {
				fill: $night;
				stroke: transparent;
			}
		}
		&:hover {
			svg path {
				@include bp-max("lg") {
					fill: transparent;
					stroke: $night;
				}
			}
		}
	}
}

.logo-oxys {
	position: relative;
	display: inline-block;
	transition: all 0.3s ease-in-out;
	width: calc(10rem + 1vw);

	svg {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		overflow: visible;
		height: auto;
		width: 100%;
		path {
			fill: $bone;
		}
	}

	&:hover {
		svg {
			path {
				stroke: $night;
				transition: all 0.3s;
				stroke-dasharray: 20;
				animation: dash 5s linear infinite;
				stroke-width: 0.5px;
				fill: transparent;
				stroke: $bone;
			}
		}
	}
}

@keyframes dash {
	0% {
		fill: transparent;
		stroke-dashoffset: 0;
		stroke-dasharray: 60;
	}
	50% {
		fill: transparent;
		stroke-dashoffset: 100;
		stroke-dasharray: 30;
	}
	100% {
		fill: transparent;
		stroke-dasharray: 60;
		stroke-dashoffset: 0;
	}
}
