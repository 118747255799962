.page-wrapper {
	perspective: 10px;
}

.parallax {
	transform-style: preserve-3d;
}

@mixin plx() {
	position: absolute;

	img {
		width: auto;
	}
}

[class^="pld-"] {
	@include plx();
}
[class^="plu-"] {
	@include plx();
}

// Parallax Down 1
.pld-1 {
	transform: translateZ(-1px) translateX(-4vw) scale(1.1);
}

// Parallax Up 1
.plu-1 {
	transform: translateZ(1px) translateX(4vw) scale(0.9);
}

// Parallax Down 2
.pld-2 {
	transform: translateZ(-2px) scale(1.2);
}

// Parallax Down 3
.pld-3 {
	transform: translateZ(-3px) scale(1.2);
}

// Parallax Up 3
.plu-3 {
	transform: translateZ(5px) translateX(16vw) scale(0.5);
}

// Parallax Down 4
.pld-4 {
	transform: translateZ(-4px) translateX(-16vw) scale(1.4);
}

// Parallax Up 4
.plu-4 {
	transform: translateZ(4px) translateX(20vw) scale(0.6);
}

// Parallax Down 5
.pld-5 {
	transform: translateZ(-5px) scale(1.5);
}

// Parallax Up 5
.plu-5 {
	transform: translateZ(5px) translateX(20vw) scale(0.5);
}

// Parallax Down 6
.pld-6 {
	transform: translateZ(-6px) scale(1.6);
}

// Parallax Down 7
.pld-7 {
	transform: translateZ(-7px) scale(1.7);
}

// Parallax Down 8
.pld-8 {
	transform: translateZ(-8px) scale(1.8);
}

// Parallax Down 9
.pld-9 {
	transform: translateZ(-9px) scale(1.9);
}

// Parallax Down 10
.pld-10 {
	transform: translateZ(-10px) scale(2);
}
