$breakpoints: (
    'xxs': 0px,
    'xs': 415px,
    'sm':  767px,
    'md': 992px,
    'lg':  1200px,
    'xl': 1720px
) !default;

$xxs: map-get($breakpoints, 'xxs');
$xs: map-get($breakpoints, 'xs');
$sm: map-get($breakpoints, 'sm');
$md: map-get($breakpoints, 'md');
$lg: map-get($breakpoints, 'lg');
$xl: map-get($breakpoints, 'xl');