html {
	overflow-x: hidden;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100vw;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	background-color: $night;
	scroll-behavior: smooth;
}

*:focus-visible {
	//mix-blend-mode: color;

	outline: thick double $bone;
	outline-offset: 0.25rem;
}

.scrolltainer {
	scroll-snap-type: y mandatory;

	.section {
		scroll-snap-align: start;
	}
}

img {
	width: 100%;
	height: auto;
}

.service-top-image {
	border-radius: 1rem;
	border: 1px solid rgba($bone, 0.25);
	box-shadow: 0 -1vmax 1vmax $night;
}

.landing-page article .landing-bg,
.service-page article .landing-bg {
	position: absolute;
	width: 100vw;
	z-index: -1;
	aspect-ratio: 16/9;
	background-size: contain;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-attachment: local;
	filter: blur(0.25vmax);
	opacity: 0.3;

	&:after {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 50%;
		background: linear-gradient(0deg, $night 0%, transparent 100%);
	}
}

.landing-analys-strategi .landing-header h1 {
	color: $milkyHoney;
}
.landing-design .landing-header h1 {
	color: $chewedGum;
	color: $chewedGum;
}
.landing-webbutveckling .landing-header h1 {
	color: $stompedGrape;
}
.landing-seo .landing-header h1 {
	color: $tastelessMint;
}
.landing-drift-support .landing-header h1 {
	color: $squeezedLime;
}

.blurred-image {
	position: relative;
	width: 100vw;
	height: 0;

	.blurred-bg {
		position: absolute;
		top: 0;
		right: 0;
		height: auto;
		width: auto;
		min-height: 50vh;
		min-width: 100vw;
		aspect-ratio: 16/9;
		left: 50%;
		transform: translateX(-50%);
		filter: blur(0.25vmax);
		opacity: 0.3;
		z-index: -1;
	}
}

p.blurred-image img {
	border-radius: 0.375rem;
	border: 1px solid rgba($bone, 0.25);
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;

	iframe,
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

a[href^="https://"]:after, a[href^="http://"]:after
{
	display: inline-block;
	height: 1em;
	aspect-ratio: 1/1;
	content: "";
	//content: url("/assets/images/external-link.svg");
	background-image: url("/assets/images/external-link.svg");
	margin-left: 0.15em;
	background-size: 1em 1em;
}

.lead-md {
	font-size: calc(1rem + 0.5vw);
	font-weight: 500;
}
